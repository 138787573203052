
import { ref, defineComponent } from 'vue'
import * as api from '../../utils/api'
import Menu from '../../components/menu/Admin.vue'
import HospitalData from '../../components/HospitalData.vue'
import { IInvoiceDB } from '../../../interfaces/invoice'
import moment from 'moment-timezone'
import { useDialog } from 'naive-ui'
moment.locale('ja')
moment.tz.setDefault('Asia/Tokyo')

export default defineComponent({
	components: {
		Menu,
		HospitalData,
	},
	data() {
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		return {
			id: '',
			loading: true,
			invoices: [] as IInvoiceDB[],
			today: moment().format('YYYY月M月D日'),
			total: 0,
			dueAt: moment().endOf('month').format('YYYY月M月D日'),
            numbers: [],
			errorDialog,
		}
	},
	mounted: function () {
		document.title = '請求書 - Vets Index'
		this.init()
	},
	methods: {
		init: async function () {
			try {
				this.loading = true
				const fileNames = location.pathname.match(/\/([a-zA-Z0-9-]+)$/)
				if (!fileNames) return false
				const fileName = fileNames[1]
				this.id = fileName
				const obj: any = location.search
					.slice(1)
					.split('&')
					.map((p) => p.split('='))
					.reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
                this.numbers = obj.numbers.split(',')
				const data = (await api.get(`/v1/admin/invoice/${fileName}?numbers=${obj.numbers}`)) as IInvoiceDB[]
				this.invoices = data
				this.loading = false
				let total = 0
				for (const i of data) total = total + i.price
				this.total = total
				const dueAtUnix = data[0].dueAt
				this.dueAt = moment(new Date(dueAtUnix * 1000)).format('YYYY月M月D日')
			} catch (e: any) {
				this.loading = false
				this.errorDialog(e, () => true)
				console.error(e)
			}
		},
		handlePaidFor: function (t: number) {
			const format = moment(`${t}`, 'YYYYMM').format('YYYY年M月')
			return `${format}`
		},
		handleUnix: function (t: number) {
			const time = new Date(t * 1000)
			return moment(time).format('YYYY年M月D日')
		},
		markAsSent: async function () {
            try {
                this.loading = true
                await api.post(`/v1/admin/invoice/mark_as`, { target: this.numbers, marker: 'sent'})
                this.$router.push(`/admin/hospital`)
                this.loading = false
            } catch (e: any) {
                this.loading = false
				this.errorDialog(e, () => true)
				console.error(e)
            }
        },
	},
})
